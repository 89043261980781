import AccountPayable from '@/domain/models/AccountPayable';
import Repository from './base/Repository';

export default class AccountPayableRepository extends Repository {
  public async find(id: string, companyGroupId: number, companyId: number) {
    const { data: response } = await this.datasource.get(
      `company-groups/${companyGroupId}/accounts-payable/${id}`,
      {
        headers: { 'company-id': JSON.stringify(companyId) },
      },
    );

    return AccountPayable.make(response.data);
  }
}
